<template>
  <div class="flex flex-col items-center w-[90%] lg:w-[70%] ml-[5%] gap-4">
    <div v-show="isMobile" @click="openBrouillon" class="flex flex-row items-center gap-4 mt-4 justify-end w-full">
      <SimpleText :content="t('showDraft')" />
      <BlueRightArrowIcon />
    </div>

    <div class="flex flex-row justify-between mx-[5%] md:mx-1 w-full" :class="{
      'mt-8': !isMobile
    }">

      <TitreUn v-if="!isAdmin" :content="t('createOrder')" />

      <div v-if="isAdmin" class="flex flex-col md:flex-row items-center w-full gap-2">
        <TitreUn :content="t('createOrderFor')" />

        <DropDownField :placeholder="t('selectAClient')" v-model="uuidClient" defaultIsNull
                       :values="utilisateurStore.clients.map(client => ({id: client.uuid, display: client.email}))" />
      </div>

      <div class="flex flex-row h-8 items-center gap-2" @click="addProduit" >
        <GreenPlusIcon />
        <SimpleText :content="t('product')" :selectable="false" />
      </div>
    </div>

    <InputField :placeholder="t('inputOrderName')" :label="t('orderName')" v-model="nomCommande" class="lg:w-full" showLabel />

    <div class="flex flex-row justify-center flex-wrap w-full gap-4">

      <CreationProduitComponent v-for="produit in produits" :key="produit.uuid" :model="produit"
          @update="produits[produits.findIndex(p => p.uuid === produit.uuid)] = produit"
          @removeProduit="removeProduit" @show3DRenderModal="show3DRenderModal" class="w-[23%]"/>
    </div>

    <div class="flex flex-row gap-4">
      <BlueButton data-testid="createDraft" :text="t('createDraft')" @click="createDraft" v-show="!uuidBrouillon" class="mb-8" />
      <BlueButton data-testid="editDraft" :text="t('editDraft')" @click="editDraft" v-show="uuidBrouillon" class="mb-8" />
      <BlueButton data-testid="confirmOrder" :text="t('confirmOrder')" @click="validateCommande" class="mb-8" />
    </div>
  </div>

  <ModalCreationCouleurComponent :isVisible="couleurStore.showModalCreation"/>
  <ModalErreur :texte="texteModalError" :close-modal="() => {isModalErrorVisible = false}" v-show="isModalErrorVisible"/>
  <Modal3DRender :isVisible="renderModal3D !== ''" :hide-modal="close3DModelModal" :base64-data="renderModal3D" />
</template>

<script setup>

import {t} from "@/utils/Traduction";
import CreationProduitComponent from "@/components/organismes/CreationProduitComponent.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import InputField from "@/components/molecules/InputField.vue";
import TitreUn from "@/components/atoms/TitreUn.vue";
import GreenPlusIcon from "@/components/atoms/GreenPlusIcon.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import ModalErreur from "@/components/organismes/ModalErreur.vue";
import ModalCreationCouleurComponent from "@/components/organismes/ModalCreationCouleurComponent.vue";
import Modal3DRender from "@/components/organismes/Modal3DRender.vue";
import {computed, defineProps, onBeforeMount, onUnmounted, reactive, ref, watch} from "vue";
import {enumeration} from "../../../Enumeration";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {router} from "@/routeur";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {CouleurStore} from "@/stores/CouleurStore";
import {CommandeStores} from "@/stores/CommandeStores";
import {BrouillonStore} from "@/stores/BrouillonStore";
import BlueRightArrowIcon from "@/components/atoms/BlueRightArrowIcon.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const couleurStore = CouleurStore();
const commandeStore = CommandeStores();
const brouillonStore = BrouillonStore();

const nomCommande = ref("");
const uuidClient = ref(null);
const texteModalError = ref("");
const isModalErrorVisible = ref(false);
const isValidationCommande = ref(false);
const renderModal3D = ref("");
let produits = reactive([]);
const hasCreateCommande = ref(false);
const uuidBrouillon = ref(null);

const isMobile = computed(() => window.innerWidth < 1024);
const isAdmin = computed(() => utilisateurStore.role === enumeration.role.ADMIN);

defineProps({
  brouillonIsOpen: Boolean,
  openBrouillon: Function
});

const clearCommande = () => {
  nomCommande.value = "";
  uuidClient.value = null;
  uuidBrouillon.value = null;
  produits.splice(0, produits.length);
  addProduit();
}

watch(() => brouillonStore.brouillonsSelected, (brouillon) => {

  if(brouillon === 0) {
    clearCommande();
    return;
  }

  uuidBrouillon.value = brouillon.uuid;
  nomCommande.value = brouillon.nom;
  uuidClient.value = brouillon.uuidClient;

  if(brouillon.produits.length > 0) {
    brouillon.produits.forEach(p => {
      p.couleur = p.uuidCouleur;
      p.quantite = p.nombre;
      p.fichier = {name: p.hasStl ? p.nom : "", content: ""};
      p.fromDraft = true;
    });
  } else {
    produits.splice(0, produits.length);
    addProduit();
  }


  produits = brouillon.produits;
});

const show3DRenderModal = (fichier) => {
  renderModal3D.value = fichier;
}

const close3DModelModal = () => {
  renderModal3D.value = "";
}

const addProduit = () => {
  produits.push({
    uuid: crypto.randomUUID(),
    nom: "",
    couleur: null,
    quantite: 1,
    remplissage: 20,
    fichier: {
      nom: "",
      content: "",
    },
    error: false
  });
}

onUnmounted(() => {
  if(!hasCreateCommande.value) {
    commandeStore.commandeEnCreation = {
      uuidBrouillon: uuidBrouillon.value,
      nom: nomCommande,
      client: uuidClient,
      produits
    }
  }
});

onBeforeMount(() => {
  if(commandeStore.commandeEnCreation) {
    uuidBrouillon.value = commandeStore.commandeEnCreation.uuidBrouillon
    nomCommande.value = commandeStore.commandeEnCreation.nom;
    uuidClient.value = commandeStore.commandeEnCreation.client;
    produits = commandeStore.commandeEnCreation.produits;

    commandeStore.commandeEnCreation = null;
  } else {
    addProduit();
  }
})

const removeProduit = (uuid) => {
  if(produits.length === 1) {
    notificationStore.addNotification(t("requireMinOneProduct"), 'error');
    return;
  }

  const index = produits.findIndex(produit => produit.uuid === uuid);
  if (index !== -1) {
    produits.splice(index, 1);
  }
}

const showModalError = (texte) => {
  texteModalError.value = texte;
  isModalErrorVisible.value = true;
}

const formatCommandeForDraft = () => {
  const produitFormated = [];
  for(let produit of produits) {
    produitFormated.push({
      nom: produit.nom,
      uuidCouleur: produit.couleur,
      nombre: produit.quantite,
      stl: produit.fichier.content,
      remplissage: produit.remplissage
    });
  }

  return {
    uuidClient: uuidClient.value,
    nom: nomCommande.value,
    produits: produitFormated
  };
}

const editDraft = async () => {
  const commandeFormated = formatCommandeForDraft();
  commandeFormated.uuid = uuidBrouillon.value;

  commandeFormated.produits.forEach(p => {
    p.uuid = p.uuidProduit;
  });

  xhr.addRequestToQueue("PUT", "/brouillons/" + uuidBrouillon.value, commandeFormated, true, true, false, async (response) => {
    if (response.code !== 204) {
      if (response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringRegisterDraft"),
            "error");

        showModalError(t("errorDuringUpdateDraft"));

      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
        showModalError(JSON.parse(response.response).message);

      }
    } else {
      notificationStore.addNotification(
          t("draftRegistered"),
          "success");

      brouillonStore.brouillons.forEach(b => {
        if(b.uuid === uuidBrouillon.value) {
          b.nom = nomCommande.value;
        }
      })
    }
  });
}

const createDraft = async () => {
  const commandeFormated = formatCommandeForDraft();

  xhr.addRequestToQueue("POST", "/brouillons", commandeFormated, true, true, false, async (response) => {
    if (response.code !== 201) {
      if (response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringRegisterDraft"),
            "error");

        showModalError(t("errorDuringCreateOrderServerError"));

      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
        showModalError(JSON.parse(response.response).message);

      }
    } else {
      notificationStore.addNotification(
          t("draftRegistered"),
          "success");

      const cmd = JSON.parse(response.response);
      const uuid = cmd.uuid;

      uuidBrouillon.value = uuid;

      brouillonStore.brouillons.push({
        uuid: uuid,
        nom: cmd.nom,
      });

      brouillonStore.brouillonsSelected = cmd;
    }
  });
}

const validateCommande = () => {
  if(isValidationCommande.value){
    return;
  }

  isValidationCommande.value = true;

  if(nomCommande.value === ""){
    notificationStore.addNotification(t("needFillCommandeName"), 'error');
    showModalError(t("needFillCommandeName"));
    isValidationCommande.value = false;
    return;
  }

  if(utilisateurStore.role === enumeration.role.ADMIN && uuidClient.value === null){
    notificationStore.addNotification(t("youNeedSelectAClient"), 'error');
    showModalError(t("youNeedSelectAClient"));
    isValidationCommande.value = false;
    return;
  }

  if(produits.length === 0){
    notificationStore.addNotification(t("youNeedToAddMinOneProduct"), 'error');
    showModalError(t("youNeedToAddMinOneProduct"));
    isValidationCommande.value = false;
    return;
  }

  if(uuidBrouillon.value) {
    xhr.addRequestToQueue("GET", "/brouillons/" + uuidBrouillon.value + "/stl", undefined, true, true, false, async (response) => {
      if (response.code !== 200) {
        if (response.code === 0 || !jsonValidator.validate(response.response)) {
          notificationStore.addNotification(
              t("errorDuringCreateOrder"),
              "error");

          showModalError(t("errorDuringCreateOrderServerError"));


          isValidationCommande.value = false;
        } else {
          notificationStore.addNotification(
              JSON.parse(response.response).message,
              "error");
          isValidationCommande.value = false;
          showModalError(JSON.parse(response.response).message);

        }
      } else {
        const produitStl = JSON.parse(response.response);

        produits.forEach(p => {
          produitStl.forEach(ps => {
            if(!p.fichier.content && ps.uuid === p.uuid) {
              p.fichier.content = ps.stl;
            }
          })
        });
        checkProduitReadyToSend();
      }
    });
  } else {
    checkProduitReadyToSend();
  }
}

const checkProduitReadyToSend = () => {
  let allProduitAreOk = true;
  for(let produit of produits) {
    if(!produit.nom || produit.nom === "" || !produit.couleur
        || !produit.quantite || produit.quantite <= 0 || !produit.fichier || produit.fichier.content === "") {

      produit.error = true;

      allProduitAreOk = false;
    }
  }

  if(!allProduitAreOk){
    notificationStore.addNotification(t('youNeedFillAllFieldsOfProduct'), 'error');
    showModalError(t("youNeedFillAllFieldsOfProduct"));
    isValidationCommande.value = false;
    return;
  }

  sendCreateCommande();
}

const sendCreateCommande = async () => {

  const produitFormated = [];
  for(let produit of produits) {
    produitFormated.push({
      nom: produit.nom,
      uuidCouleur: produit.couleur,
      nombre: produit.quantite,
      stl: produit.fichier.content,
      remplissage: produit.remplissage
    });
  }


  const commandeFormated = {
    uuidBrouillon: uuidBrouillon.value,
    nom: nomCommande.value,
    produits: produitFormated
  };


  const url = "/commandes/create" + (uuidClient.value !== null ? "/" + uuidClient.value : "");

  xhr.addRequestToQueue("POST", url, commandeFormated, true, true, false, async (response) => {
    if (response.code !== 201) {
      if (response.code === 0 || !jsonValidator.validate(response.response)) {
        notificationStore.addNotification(
            t("errorDuringCreateOrder"),
            "error");

        showModalError(t("errorDuringCreateOrderServerError"));


        isValidationCommande.value = false;
      } else {
        notificationStore.addNotification(
            JSON.parse(response.response).message,
            "error");
        isValidationCommande.value = false;
        showModalError(JSON.parse(response.response).message);

      }
    } else {
      notificationStore.addNotification(
          t("orderCreated"),
          "success");
      isValidationCommande.value = false;

      const commandeResponse = JSON.parse(response.response);
      commandeStore.commandes.unshift({
        uuid: commandeResponse.uuid,
        nom: nomCommande.value,
        utilisateur: commandeResponse.utilisateur,
        uuidUtilisateur: commandeResponse.uuidUtilisateur,
        dateDebut: new Date().toISOString(),
        etat: {libelle: enumeration.etatCommande.A_FAIRE},
        produits: produits
      });

      hasCreateCommande.value = true;

      if(uuidBrouillon.value) {
        console.log("filtrer le store", uuidBrouillon)
        brouillonStore.brouillons = brouillonStore.brouillons.filter(b => b.uuid !== uuidBrouillon.value)
      }

      brouillonStore.brouillonsSelected = 0;

      if(isAdmin.value) {
        await router.push({path: "/admin/commandes"});
      } else {
        await router.push({ path: "/commandes"});
      }
    }
  });
}
</script>

<style scoped>

</style>