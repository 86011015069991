<template>
  <div class="flex flex-col justify-start items-start bg-white p-8 rounded-xl mt-4 w-full md:w-[48%] lg:w-[31%] gap-4"
       :class="{'border-red-600 border-2': model.error}">

    <InputField :placeholder="t('inputProductName')" :label="t('productName')" showLabel
          v-model="localModel.nom" required class="!w-full" @onChange="resetError" />

    <div class="w-full">
      <DropDownField :placeholder="t('selectAColor')" :label="t('color')" required v-model="localModel.couleur" class="!w-full"
             :values="couleurStore.couleurs.map(c => ({id: c.uuid, display: c.libelle}))" showLabel @change="resetError" defaultIsNull />

      <BlueClickableText :text="t('addColor')" @click="createCouleur"/>
    </div>
    <InputField :placeholder='t("inputQuantity")' :label="t('quantity')" required v-model="localModel.quantite"
            showLabel class="!w-full" type="number" :min="1" @onChange="resetError"/>

    <DropDownField placeholder="" :label="t('filling')" required v-model="localModel.remplissage" class="!w-full"
           :values="utils.getFillingValues()" showLabel @change="resetError" />

    <PurpleFileInputField v-model="localModel.fichier" :defaultValue="localModel.fichier.name" @change="resetError" class="w-full" />

    <BlueButton :text="t('show3D')" @click="show3DObject"
                v-show="localModel.fichier.content !== '' || (localModel.fromDraft && localModel.fichier.name !== '' || localModel.hasStl)"
                class="w-full" />

    <RedButton data-testid="suppressionProduit" :text="t('deleteAProduct')" @click="emit('removeProduit', localModel.uuid);" class="w-full" />
  </div>
</template>

<script setup>

import {defineEmits, defineProps, ref, watch} from "vue";
import {CouleurStore} from "@/stores/CouleurStore";
import {t} from "@/utils/Traduction";
import InputField from "@/components/molecules/InputField.vue";
import DropDownField from "@/components/molecules/DropDownField.vue";
import BlueClickableText from "@/components/molecules/BlueClickableText.vue";
import PurpleFileInputField from "@/components/molecules/PurpleFileInputField.vue";
import BlueButton from "@/components/molecules/BlueButton.vue";
import RedButton from "@/components/molecules/RedButton.vue";
import {utils} from "@/utils/other";
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";

const notificationStore = NotificationStores();

const emit = defineEmits(["removeProduit", "show3DRenderModal"]);
const props = defineProps({
  model: {
    type: Object,
    required: true
  }
});

const localModel = ref(props.model);

if(localModel.value.fromDraft && localModel.value.nom === "" && localModel.value.hasStl) {
  localModel.value.fichier.name = "Sans nom";
}

watch(() => localModel.value.fichier, () => {
  localModel.value.fromDraft = false;
})

watch(() => props.model, (newVal) => {
  localModel.value = newVal;

  if(newVal.fromDraft && localModel.value.nom === "") {
    localModel.value.fichier.name = "Sans nom";
  }
});

const resetError = () => {
  localModel.value.error = false;
}

const couleurStore = CouleurStore();

couleurStore.loadCouleurs();

const createCouleur = () => {
  couleurStore.showModalCreation = true;
}

const show3DObject = () => {
  if(localModel.value.fromDraft) {
    xhr.addRequestToQueue("GET", "/brouillons/stl/" + localModel.value.uuid, undefined, true, true, false,
        (response) => {
          if (response.code !== 200) {
            if (response.code === 0 || !JSON.parse(response.response)) {
              notificationStore.addNotification(
                  t("errorDuringGetStl"),
                  "error");
            } else {
              notificationStore.addNotification(
                  JSON.parse(response.response).message,
                  "error");
            }
          } else {
            const stl = JSON.parse(response.response).message

            localModel.value.fichier.content = stl;
            localModel.value.fromDraft = false;
            emit('show3DRenderModal', stl);
          }
        });
  } else {
    emit('show3DRenderModal', localModel.value.fichier.content)
  }
}
</script>

<style scoped>

</style>