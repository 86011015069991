<template>
  <div class="flex flex-row relative">
    <CreationCommandeForm :brouillonIsOpen="brouillonIsOpen" :openBrouillon="() => brouillonIsOpen = true"  />
    <BrouillonList :brouillonIsOpen="brouillonIsOpen" :closeBrouillon="() => brouillonIsOpen = false"/>
  </div>
</template>

<script setup>

import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {router} from "@/routeur";
import {NotificationStores} from "@/stores/NotificationStores";
import {t} from "@/utils/Traduction";
import CreationCommandeForm from "@/components/organismes/CreationCommandeForm.vue";
import BrouillonList from "@/components/organismes/BrouillonList.vue";
import {ref} from "vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();

if(!utilisateurStore.isConnected) {
  notificationStore.addNotification(t("youNeedToBeConnectedToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

const brouillonIsOpen = ref(window.innerWidth > 1024);

utilisateurStore.loadClients();

</script>

<style scoped>

</style>