import {defineStore} from 'pinia'
import xhr from "@/utils/xhr";
import {NotificationStores} from "@/stores/NotificationStores";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";

export const BrouillonStore = defineStore("BrouillonStore", {
    state: () => ({
        brouillons: [],
        brouillonsSelected: 0
    }),
    actions: {
        async loadBrouillons() {
            if(this.brouillons.length > 0) {
                return;
            }

            const notificationStore = NotificationStores();

                xhr.addRequestToQueue("GET", "/brouillons", undefined, true, true, false,
            (response) => {
                if (response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetDraft"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.brouillons = JSON.parse(response.response);
                }
            });
        },
        async loadBrouillonByUuid(uuid) {
            const notificationStore = NotificationStores();

            if(this.brouillonsSelected && this.brouillonsSelected.uuid === uuid) {
                return;
            }

            xhr.addRequestToQueue("GET", "/brouillons/" + uuid, undefined, true, true, false,
                (response) => {
                    if (response.code !== 200) {
                        if (response.code === 0 || !jsonValidator.validate(response.response)) {
                            notificationStore.addNotification(
                                t("errorDuringGetDraft"),
                                "error");
                        } else {
                            notificationStore.addNotification(
                                JSON.parse(response.response).message,
                                "error");
                        }
                    } else {
                        this.brouillonsSelected = JSON.parse(response.response);
                    }
                });
        }
    },
    getters : {}
});
