<template>
  <div class="flex flex-row justify-between items-center w-full hover:bg-gray-300 py-1 px-2 rounded-l cursor-pointer"
     :class="{'border-green-600 border': brouillon.uuid === brouillonStore.brouillonsSelected.uuid}"
     v-on:click="brouillonStore.loadBrouillonByUuid(brouillon.uuid)">
    <SimpleText :content="brouillon.nom" :selectable="false" />
    <RedTrashIcon v-on:click.stop="deleteDraft" />
  </div>
</template>

<script setup>

import {defineProps} from "vue"
import SimpleText from "@/components/atoms/SimpleText.vue";
import RedTrashIcon from "@/components/atoms/RedTrashIcon.vue";
import xhr from "@/utils/xhr";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import {NotificationStores} from "@/stores/NotificationStores";
import {BrouillonStore} from "@/stores/BrouillonStore";

const notificationStore = NotificationStores();
const brouillonStore = BrouillonStore();

const props = defineProps({
  brouillon: {
    type: Object,
    required: true
  }
});

const deleteDraft = () => {
  xhr.addRequestToQueue("DELETE", "/brouillons/" + props.brouillon.uuid, undefined, true, true, false,
      (response) => {
        if (response.code !== 204) {
          if (response.code === 0 || !jsonValidator.validate(response.response)) {
            notificationStore.addNotification(
                t('errorDuringDeleteDraft'),
                "error");
          } else {
            notificationStore.addNotification(
                JSON.parse(response.response).message,
                "error");
          }
        } else {
          notificationStore.addNotification(t("draftDeleted"), "success");

          if(brouillonStore.brouillonsSelected.uuid === props.brouillon.uuid) {
            brouillonStore.brouillonsSelected = 0;
          }

          brouillonStore.brouillons = brouillonStore.brouillons.filter(b => b.uuid !== props.brouillon.uuid)
        }
      });
}

</script>

<style scoped>

</style>