<template>
  <div :class="$attrs.class">
    <span v-if="!dangerouslySetInnerHTML" :class="{
      [color]: true,
      'text-center': textCenter,
      [bolding]: true,
      [size]: true,
      'select-none': !selectable
    }">{{text}}</span>

    <div v-if="dangerouslySetInnerHTML" v-html="html" :class="{
      [color]: true,
      'text-center': textCenter,
      [bolding]: true,
      [size]: true,
      'select-none': !selectable
    }"
    data-testid="dangerousHTML"/>
  </div>
</template>

<script setup>

import {computed, defineProps} from 'vue';

const props = defineProps({
  content: {
    type: [String, Number],
    required: true
  },
  dangerouslySetInnerHTML: {
    type: Boolean,
    default: false
  },
  textCenter: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    required: false,
    default: "text-black"
  },
  size: {
    type: String,
    default: "text-base"
  },
  bolding: {
    type: String,
    default: "font-normal"
  },
  selectable: {
    type: Boolean,
    default: true
  }
});

const html = computed(() => props.dangerouslySetInnerHTML ? props.content : "")
const text = computed(() => props.dangerouslySetInnerHTML ? "" : props.content)

</script>

<style scoped>

</style>