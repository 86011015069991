<template>
  <div v-if="brouillonIsOpen" class="flex flex-col lg:m-4 pt-4 lg:pt-0 items-center lg:w-1/5 absolute top-0 right-0
      bottom-0 w-4/5 bg-white lg:bg-transparent h-auto">
    <BlackCrossIcon v-show="isMobile" @click="closeBrouillon" class="absolute top-4 left-4 text-3xl" />
    <TitreDeux :content="t('mydraft')" class="text-center" />
    <BlueSpacer class="w-4/5" />

    <div class="flex flex-col mt-4 gap-2 w-4/5">
      <div class="flex flex-row mx-2 items-center cursor-pointer" v-on:click="brouillonsStores.brouillonsSelected = 0">
        <GreenPlusIcon class="w-[24px] h-[24px] mr-2" />
        <SimpleText :content="t('newOrder')" :selectable="false" />
      </div>
      <BrouillonIdCard v-for="brouillon in brouillonsStores.brouillons" :key="brouillon.uuid" :brouillon="brouillon" />
    </div>
  </div>
</template>

<script setup>

import TitreDeux from "@/components/atoms/TitreDeux.vue";
import {t} from "@/utils/Traduction";
import BlueSpacer from "@/components/atoms/BlueSpacer.vue";
import {BrouillonStore} from "@/stores/BrouillonStore";
import BrouillonIdCard from "@/components/molecules/BrouillonIdCard.vue";
import GreenPlusIcon from "@/components/atoms/GreenPlusIcon.vue";
import SimpleText from "@/components/atoms/SimpleText.vue";
import {computed, defineProps} from "vue";
import BlackCrossIcon from "@/components/atoms/BlackCrossIcon.vue";

defineProps({
  brouillonIsOpen: Boolean,
  closeBrouillon: Function
});

const brouillonsStores = BrouillonStore();

brouillonsStores.loadBrouillons();

const isMobile = computed(() => window.innerWidth < 1024);

</script>

<style scoped>
</style>