<template>
  <div class="flex flex-col h-auto mx-auto p-4 gap-8">
    <CreateCoilForm />

    <StatsCoil />

    <CoilList />
  </div>
</template>

<script setup>
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {NotificationStores} from "@/stores/NotificationStores";
import {BobineStore} from "@/stores/BobineStore";
import {enumeration} from "../../Enumeration";
import {router} from "@/routeur";
import {CouleurStore} from "@/stores/CouleurStore";
import {t} from "@/utils/Traduction";
import {MarqueStore} from "@/stores/MarqueStore";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";
import CreateCoilForm from "@/components/organismes/CreateCoilForm.vue";
import StatsCoil from "@/components/organismes/StatsCoil.vue";
import CoilList from "@/components/organismes/CoilList.vue";

const utilisateurStore = UtilisateurStores();
const notificationStore = NotificationStores();
const bobineStore = BobineStore();
const couleurStore = CouleurStore();
const marqueStore = MarqueStore();
const typeFilamentStore = TypeFilamentStore();

if(utilisateurStore.role !== enumeration.role.ADMIN) {
  utilisateurStore.logout(false);
  notificationStore.addNotification(t("youNeedToBeAdminToAccessThisPage"), 'error');
  router.push({ path: "/"});
}

bobineStore.loadBobines();
couleurStore.loadCouleurs();
marqueStore.loadMarques();
typeFilamentStore.loadTypeFilaments();
</script>

<style scoped>

</style>