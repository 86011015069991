import {defineStore} from 'pinia';
import {NotificationStores} from "@/stores/NotificationStores";
import xhr from "@/utils/xhr";
import {UtilisateurStores} from "@/stores/UtilisateurStores";
import {enumeration} from "../../Enumeration.js";
import {jsonValidator} from "@/utils/JsonValidator";
import {t} from "@/utils/Traduction";
import {MarqueStore} from "@/stores/MarqueStore";
import {CouleurStore} from "@/stores/CouleurStore";
import {TypeFilamentStore} from "@/stores/TypeFilamentStore";

export const BobineStore = defineStore("BobineStore", {
    state: () => ({
        bobines: []
    }),
    actions: {
        async loadBobines() {
            const utilisateurStore = UtilisateurStores();
            if(this.bobines.length > 0 || utilisateurStore.role !== enumeration.role.ADMIN) {
                return;
            }

            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("GET", "/bobines", undefined, true, true, false,
            (response) => {
                if(response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringGetCoil"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    this.bobines = JSON.parse(response.response);
                }
            });
        },

        async deleteBobine(id) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("DELETE", "/bobines/" + id, undefined, true, true, false,
            (response) => {
                if(response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringDeleteCoil"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    notificationStore.addNotification(t("coilDeleted"), 'success');
                }
            });
        },

        async createBobine(bobine) {
            const notificationStore = NotificationStores();

            xhr.addRequestToQueue("POST", "/bobines", bobine, true, true, false,
            (response) => {
                if(response.code !== 201) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringCreateCoil"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    const bobine = JSON.parse(response.response);
                    bobine.couleur = CouleurStore().couleurs.find((couleur) => couleur.uuid === bobine.uuidCouleur);
                    bobine.marque = MarqueStore().marques.find((marque) => marque.uuid === bobine.uuidMarque);
                    bobine.typeFilament = TypeFilamentStore().typeFilaments.find((typeFilament) => typeFilament.uuid === bobine.uuidTypeFilament);
                    this.bobines.push(bobine);

                    notificationStore.addNotification(t("coilCreated"), 'success');
                }
            });
        },

        async modifierBobine(uuid, poids, uuidCouleur, uuidMarque, uuidTypeFilament) {
            const notificationStore = NotificationStores();
            const marqueStore = MarqueStore();
            const couleurStore = CouleurStore();
            const typeFilamentStore = TypeFilamentStore();

            const data = {poidsRestant: poids, uuidCouleur, uuidMarque, uuidTypeFilament};

            xhr.addRequestToQueue("PUT", "/bobines/" + uuid, data, true, true, false,
            (response) => {
                if(response.code !== 200) {
                    if (response.code === 0 || !jsonValidator.validate(response.response)) {
                        notificationStore.addNotification(
                            t("errorDuringEditCoil"),
                            "error");
                    } else {
                        notificationStore.addNotification(
                            JSON.parse(response.response).message,
                            "error");
                    }
                } else {
                    const bobine = this.bobines.find(bobine => bobine.uuid === uuid);
                    bobine.poidsRestant = poids;
                    bobine.uuidMarque = uuidMarque;
                    bobine.uuidCouleur = uuidCouleur;
                    bobine.uuidTypeFilament = uuidTypeFilament;

                    bobine.marque = marqueStore.marques.find((marque) => marque.uuid === uuidMarque);
                    bobine.couleur = couleurStore.couleurs.find((couleur) => couleur.uuid === uuidCouleur);
                    bobine.typeFilament = typeFilamentStore.typeFilaments.find((typeFilament) => typeFilament.uuid === uuidTypeFilament);

                    notificationStore.addNotification(t("coilEdited"), 'success');
                }
            });
        }
    },
    getters : {}
});
